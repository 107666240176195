import { apiGet } from 'actions/data_providers/api';
import { ActionCreator } from 'actions/types';
import { getCurrentUserToken } from 'selectors/getCurrentUser';
import { getCurrentVenueId } from 'selectors/getCurrentVenue';
import { Logger } from 'utils/logger';

import { SalesforceAuthTokens } from './types';

const logger = new Logger('SalesforceChat.Authorisation');

export class GetAuthTokensActionError extends Error {}

export const getAuthTokensAction: ActionCreator<Promise<SalesforceAuthTokens>> = () => (dispatch, getState) =>
  new Promise<SalesforceAuthTokens>((resolve, reject) => {
    const venueId = getCurrentVenueId(getState());
    const auth = getCurrentUserToken(getState());

    if (!venueId || !auth) {
      return reject({ code: 400 });
    }

    const endpointPath = `/venues/${venueId}/salesforce/auth/generate_token`;
    dispatch(
      apiGet({
        path: endpointPath,
        auth,
        onResponse(response) {
          const data = response as unknown as SalesforceAuthTokens;

          if (!data.chatSessionToken || !data.profileAccessToken) {
            logger.error(`${endpointPath} missing tokens`, data);
            return reject(new GetAuthTokensActionError('missing tokens'));
          }
          const { chatSessionToken, profileAccessToken } = data;
          resolve(<SalesforceAuthTokens>{ chatSessionToken, profileAccessToken });
        },
      })
    );
  });
