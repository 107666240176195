import { SalesforceEnv } from 'env';
import { intervalCheck } from 'utils/intervalCheck';
import { NotOptionalRecord } from 'utils/utilityTypes';

import { buildSalesforceChatError, onSalesforceError } from './errorHandling';
import { RegExpValidation, SalesforceChatConfigError } from './types';

const API_LOAD_TIMEOUT = 20000;

const assertEnv = (env: SalesforceEnv, envKey: keyof SalesforceEnv, [regex, regexExplanation]: RegExpValidation) => {
  const value = (env && env[envKey]) ?? '';
  if (!value || !value.match(regex))
    throw new SalesforceChatConfigError(
      `${envKey} is not properly set in the environment, received "${String(
        value
      )}". Expected to ${regexExplanation} with RegExp ${regex}`
    );
};
const ID_NOT_EMPTY_VALIDATION: RegExpValidation = [/^\w+$/, 'No Spaces, and Not be empty'];
const VALID_URL_VALIDATION: RegExpValidation = [
  // eslint-disable-next-line no-useless-escape
  /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
  'be a valid url',
];
export const assertEnvDefined = (env: SalesforceEnv): NotOptionalRecord<SalesforceEnv> => {
  assertEnv(env, 'UALA_SALESFORCE_ORG_ID', ID_NOT_EMPTY_VALIDATION);
  assertEnv(env, 'UALA_SALESFORCE_ESW_CONFIG_DEV_NAME', ID_NOT_EMPTY_VALIDATION);
  assertEnv(env, 'UALA_SALESFORCE_SITE_URL', VALID_URL_VALIDATION);
  assertEnv(env, 'UALA_SALESFORCE_SCRT2URL', VALID_URL_VALIDATION);
  assertEnv(env, 'UALA_SALESFORCE_BOOTSTRAP_CODE_URL', VALID_URL_VALIDATION);

  return env as NotOptionalRecord<SalesforceEnv>;
};

const isSalesforceAPILoaded = () =>
  Boolean(window.embeddedservice_bootstrap && window.embeddedservice_bootstrap.settings);

export const waitForSalesforceInitialized = () =>
  new Promise((resolve) =>
    intervalCheck({
      check: isSalesforceAPILoaded,
      onSuccess: resolve,
      onFailure: () => {
        onSalesforceError(buildSalesforceChatError(`API didn't load after ${API_LOAD_TIMEOUT / 1000}s`));
      },
      step: 10,
      timeout: API_LOAD_TIMEOUT,
    })
  );
export const SFApi = () => window.embeddedservice_bootstrap;
