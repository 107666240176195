/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/ban-ts-comment */

import { User, Venue } from 'models';
import { ValidURLString } from 'utils/tests/mockServerTools';

//@ts-ignore
type LoadAddEventListener<K extends keyof HTMLElementEventMap> = HTMLScriptElement['addEventListener'];
export type LoadListener = Parameters<LoadAddEventListener<'load'>>[1];
export type RegExpValidation = [RegExp, string];
export type SalesforceChatPreChatFields = Partial<
  Readonly<{
    externalUserFullName: string;
    externalUserId: string;
    externalVenueId: string;
    externalLanguage: string;
    externalSource: string;
    externalUserEmail: string;
    externalCountry: string;
    profileAccessToken: string;
  }>
>;

export type SalesforceAuthTokens = {
  chatSessionToken: string;
  profileAccessToken: string;
};

type SnippetConfig = {
  scrt2URL: string;
  hideChatButtonOnLoad: boolean;
};

type embeddedservice_bootstrap_type = {
  utilAPI: {
    launchChat: () => void;
  };
  userVerificationAPI: {
    setIdentityToken: (params: {
      identityTokenType: 'JWT';
      identityToken: SalesforceAuthTokens['chatSessionToken'];
    }) => void;
  };
  init: (orgId: string, eswConfigDevName: string, siteURL: ValidURLString, snippetConfig: SnippetConfig) => void;
  settings: {
    language: string;
  };
  prechatAPI: {
    setHiddenPrechatFields: (fields: SalesforceChatPreChatFields) => void;
  };
};

declare global {
  interface Window {
    embeddedservice_bootstrap: embeddedservice_bootstrap_type;
  }
}
export class SalesforceChatConfigError extends Error {}

export type UserAndVenue = {
  user: User;
  venue: Venue;
};

export type AuthTokensClientParams = {
  getAuthTokens: () => Promise<SalesforceAuthTokens>;
};
