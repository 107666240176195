/** merges the first with the first args if args[0] is string
 * SO THAT when using the %c for styling works
 */
const fixedArgs = (first: string, ...args: unknown[]) => {
  if (typeof args[0] !== 'string') return [first, ...args];

  args[0] = `${first} ${args[0]}`;
  return args;
};

const DEBUG = process.env.NODE_ENV === 'development';

type ConsoleLogger = Pick<Console, 'trace' | 'debug' | 'log' | 'info' | 'warn' | 'error'>;

/**
 * Console-like interface that only produce output for warn and error in production code, and will enable all log levels in development
 */
export class Logger implements ConsoleLogger {
  public constructor(
    private namespace: string,
    private enabled: boolean = true
  ) {}

  private base(method: keyof ConsoleLogger, ...args: unknown[]): void {
    const _args: unknown[] = args ? args : [];
    console[method](...fixedArgs(`${this.namespace}: `, ..._args));
  }

  public trace(...args: unknown[]): void {
    if (!DEBUG || !this.enabled) return;
    this.base('trace', ...args);
  }
  public debug(...args: unknown[]): void {
    if (!DEBUG || !this.enabled) return;
    this.base('debug', ...args);
  }
  public log(...args: unknown[]): void {
    if (!DEBUG || !this.enabled) return;
    this.base('log', ...args);
  }
  public info(...args: unknown[]): void {
    if (!DEBUG || !this.enabled) return;
    this.base('info', ...args);
  }

  public warn(...args: unknown[]): void {
    this.base('warn', ...args);
  }
  public error(...args: unknown[]): void {
    this.base('error', ...args);
  }
}
