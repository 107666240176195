import { captureException, withScope } from '@sentry/core';
import { Logger } from 'utils/logger';

export type ErrorBuilder<T extends Error> = (message: string) => T;
export const buildError: ErrorBuilder<Error> = (message: string) => new Error(message);

const anyToString = (e: any): string => {
  try {
    if (Array.isArray(e) || typeof e === 'object') return JSON.stringify(e, null, '  ');
  } catch (e) {
    return `<cannot convert captured error to string default conversion: ${String(e)}>`;
  }

  return String(e);
};

export const catchedArgumentToErrorBuilder =
  <T extends Error>(_buildError: ErrorBuilder<T>) =>
  (e: unknown, namespace: string): T => {
    if (!e) return _buildError('UNKNOWN ERROR');
    const ErrorConstructor = _buildError('dummy error').constructor;

    let err: T;
    if (!(e instanceof Error)) err = _buildError(anyToString(e));
    else if (e instanceof ErrorConstructor) {
      err = e as unknown as T;
    } else {
      err = _buildError(String(e.message));
      err.stack = e.stack;
    }

    err.message = `[${namespace}]: ${err.message}`;
    return err;
  };

export const catchedArgumentToError = catchedArgumentToErrorBuilder(buildError);

// eslint-disable-next-line @typescript-eslint/ban-types
export const fixStackTrace = (e: Error, target: Function) => {
  if ('captureStackTrace' in Error) Error.captureStackTrace(e, target);
  return e;
};

export const withCatchToErrorWithContext =
  (context: Record<string, unknown>) =>
  <F extends (...args: any) => any>(namespace: string, fn: F, onErrorReturnValue?: ReturnType<F>) =>
  (...args: Parameters<F>) => {
    const logger = new Logger(namespace);
    try {
      return fn(...args);
    } catch (e) {
      const err = catchedArgumentToError(e, namespace);

      withScope((scope) => {
        scope.setExtras(context);
        scope.setExtra('arguments', args);

        logger.error(err);
        captureException(err);
      });
      return onErrorReturnValue !== undefined ? onErrorReturnValue : err;
    }
  };

/**
 * Returns a Monad of the given function and send the error to Sentry and to the console
 */
export const withCatchToError = withCatchToErrorWithContext({});
