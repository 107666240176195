type NavigatorSegregation = {
  userAgent: Navigator['userAgent'];
  platform: Navigator['platform'];
  maxTouchPoints: Navigator['maxTouchPoints'];
};

export type SalesforceExternalSource = 'Web' | 'iOS' | 'Android';

export const externalSourceGetter = (_navigator: NavigatorSegregation) => (): SalesforceExternalSource => {
  const userAgent = _navigator.userAgent;
  if (/android/i.test(userAgent)) {
    return 'Android';
  } else if (
    /iPad|iPhone|iPod/.test(userAgent) ||
    (_navigator.platform === 'MacIntel' && _navigator.maxTouchPoints > 1)
  ) {
    return 'iOS';
  }
  return 'Web';
};

export const adaptNavigator = (navigator: Partial<NavigatorSegregation>): NavigatorSegregation => ({
  userAgent: navigator.userAgent ?? '',
  platform: navigator.platform ?? (navigator as any).userAgentData?.platform ?? '',
  maxTouchPoints: navigator.maxTouchPoints ?? 1,
});
