import { Spinner } from 'components/core';
import { useSalesforceChat } from './useSalesforceChat';
import { FC } from 'react';

export const SalesForceChat: FC = () => {
  const isLoading = useSalesforceChat();
  return isLoading ? (
    <div className="SalesforceChatSkeleton">
      <Spinner loading={true} />
    </div>
  ) : null;
};
