import { dispatchCustomEvent } from 'customEvents';
import { intervalCheck } from 'utils/intervalCheck';

import { SALESFORCE_CHAT_ERROR, SALESFORCE_CHAT_OPENED, SALESFORCE_CHAT_OPENED_REQUEST } from './customEvents';
import { onSalesforceError, buildSalesforceChatError, addToSalesforceErrorContext } from './errorHandling';
import { AuthTokensClientParams } from './types';
import { setIdentityToken } from './setIdentityToken';
import { SFApi } from './utils';

export const SALESFORCE_OPEN_CHAT_TIMEOUT = 30000;

const checkSalesforceChatOpened = () => {
  const chatElm = document.querySelector('#embedded-messaging .embeddedMessagingFrame');
  if (!chatElm) return false;

  const clientRect = chatElm.getBoundingClientRect();
  if (!clientRect.width || clientRect.width < 0) return false;
  if (!clientRect.height || clientRect.height < 0) return false;

  return true;
};

const onSFChatFailToOpen = (
  e: Error = buildSalesforceChatError(`Salesforce chat not opening after ${SALESFORCE_OPEN_CHAT_TIMEOUT / 1000}s!`)
) => {
  dispatchCustomEvent(SALESFORCE_CHAT_ERROR);
  addToSalesforceErrorContext('originalFailToOpenError', e);
  onSalesforceError(buildSalesforceChatError('Salesforce chat not opening due Error'));
};

const onSFChatSucceedOpening = () => dispatchCustomEvent(SALESFORCE_CHAT_OPENED);

export const openSalesforce: (params: AuthTokensClientParams) => Promise<void> = async ({ getAuthTokens }) => {
  dispatchCustomEvent(SALESFORCE_CHAT_OPENED_REQUEST);

  const { chatSessionToken, profileAccessToken } = await getAuthTokens();

  const intervalCheckHandler = intervalCheck({
    check: checkSalesforceChatOpened,
    onFailure: onSFChatFailToOpen,
    onSuccess: onSFChatSucceedOpening,
    step: 250,
    timeout: SALESFORCE_OPEN_CHAT_TIMEOUT,
  });

  try {
    setIdentityToken(chatSessionToken);
    SFApi().prechatAPI.setHiddenPrechatFields({ profileAccessToken }); // setHiddenPrechatFields merge with previous set data, so this only updates profileAccessToken
    SFApi().utilAPI.launchChat();
  } catch (e) {
    onSFChatFailToOpen(e instanceof Error ? e : new Error(`Error calling launchChat: ${String(e)}`));
    intervalCheckHandler.stop();
  }
};
