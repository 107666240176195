import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { captureMessage } from '@sentry/core';
import { ActionDispatch } from 'actions/types';
import ENV, { SalesforceEnv } from 'env';
import { ACTIVE_FLAGS, useFlag } from 'feature-flags';
import { getCurrentUser } from 'selectors/getCurrentUser';
import { getCurrentVenue } from 'selectors/getCurrentVenue';
import { loadScript } from 'utils/loadScript';
import { Logger } from 'utils/logger';
import { withCatchToError } from 'utils/sentryUtils';
import { ValidURLString } from 'utils/tests/mockServerTools';
import { NotOptionalRecord } from 'utils/utilityTypes';

import { externalSourceGetter } from './_getExternalSource';
import { SALESFORCE_CHAT_ERROR, SALESFORCE_CHAT_OPENED, SALESFORCE_CHAT_OPENED_REQUEST } from './customEvents';
import { addToSalesforceErrorContext, withCatchToSalesforceError } from './errorHandling';
import { getAuthTokensAction } from './getAuthTokensAction';
import { openSalesforce } from './openSalesforce';
import { setIdentityToken } from './setIdentityToken';
import { AuthTokensClientParams, SalesforceAuthTokens, SalesforceChatPreChatFields, UserAndVenue } from './types';
import { assertEnvDefined, waitForSalesforceInitialized } from './utils';

import './salesforceChatOverrides.css';

const logger = new Logger('SalesforceChat');
const salesforceChatLuncherSelector = '.salesforce-chat-support';

export const useSalesforceChat = withCatchToError(
  'SalesforceChat:useSalesforceChat',
  () => {
    const { salesforceENV, salesforceChatDisabled, user, venue, isLoading, setIsLoading, getAuthTokens } =
      useSalesforceChatContext();

    useStartSalesforceChatWhenPossible({
      salesforceChatDisabled,
      venue,
      user,
      salesforceENV,
      onLoad: () => setIsLoading(false),
      getAuthTokens,
    });
    useCustomSalesforceDOMEventsToIsLoading(setIsLoading);
    useListenDomEventToOpenSalesforceChat(salesforceChatDisabled, getAuthTokens);

    return isLoading;
  },
  false
);

const useSalesforceChatContext = () => {
  const salesforceENV = assertEnvDefined(ENV);
  const salesforceChatDisabled = !useFlag(ACTIVE_FLAGS.FE_CHAT_SALESFORCE);

  const user = useSelector(getCurrentUser);
  const venue = useSelector(getCurrentVenue);

  const dispatch = useDispatch<ActionDispatch>();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getAuthTokens = useCallback(async () => dispatch(getAuthTokensAction()), [dispatch]);
  return {
    salesforceENV,
    salesforceChatDisabled,
    user,
    venue,
    isLoading,
    setIsLoading,
    getAuthTokens,
  };
};

const getExternalSource = externalSourceGetter(window.navigator);

const initSalesforceChat = withCatchToSalesforceError(
  'SalesforceChat:initSalesforceChat',
  async (prechatFields: SalesforceChatPreChatFields, chatSessionToken: SalesforceAuthTokens['chatSessionToken']) => {
    logger.debug('initEmbeddedMessaging: Waiting for Salesforce API to be available...');
    await waitForSalesforceInitialized();
    logger.debug('initEmbeddedMessaging: API Ready!', window.embeddedservice_bootstrap);
    logger.debug('initEmbeddedMessaging: Starting...', window.embeddedservice_bootstrap);
    const salesforceENV = assertEnvDefined(ENV);

    window.embeddedservice_bootstrap.settings.language = 'en_US'; // For example, enter 'en' or 'en-US'
    window.addEventListener(
      'onEmbeddedMessagingReady',
      withCatchToSalesforceError('SalesforceChat:initSalesforceChat:onEmbeddedMessagingReady', () => {
        logger.debug('onEmbeddedMessagingReadyListner: Setting Prechat Fields', prechatFields);
        window.embeddedservice_bootstrap.prechatAPI.setHiddenPrechatFields(prechatFields);
        setIdentityToken(chatSessionToken);
      })
    );

    const initArgs: Parameters<typeof window.embeddedservice_bootstrap.init> = [
      salesforceENV.UALA_SALESFORCE_ORG_ID,
      salesforceENV.UALA_SALESFORCE_ESW_CONFIG_DEV_NAME,
      <ValidURLString>salesforceENV.UALA_SALESFORCE_SITE_URL,
      {
        scrt2URL: salesforceENV.UALA_SALESFORCE_SCRT2URL,
        hideChatButtonOnLoad: true,
      },
    ];
    addToSalesforceErrorContext('initArgs', initArgs);

    window.embeddedservice_bootstrap.init(...initArgs);
    logger.debug('initEmbeddedMessaging: window.embeddedservice_bootstrap.init called...', initArgs);
  }
);

type UseStartSalesforceChatWhenPossibleParams = Partial<NullableObject<UserAndVenue>> & {
  salesforceChatDisabled: boolean;
  salesforceENV: NotOptionalRecord<SalesforceEnv>;
  onLoad: () => void;
  getAuthTokens: () => Promise<SalesforceAuthTokens>;
};

function useStartSalesforceChatWhenPossible({
  salesforceChatDisabled,
  venue,
  user,
  salesforceENV,
  onLoad,
  getAuthTokens,
}: UseStartSalesforceChatWhenPossibleParams) {
  const [wasExecuted, setWasExecuted] = useState<boolean>(false);
  useEffect(() => {
    if (salesforceChatDisabled || wasExecuted) return;

    logger.debug('useSalesforceChat: venue, user', venue, user);
    if (!venue || !user) return;

    addToSalesforceErrorContext('user', user);
    // addToSalesforceErrorContext('venue', venue); // venue has way to much data

    startSalesforceChat({
      user,
      venue,
      salesforceENV,
      onLoad,
      getAuthTokens,
    });
    setWasExecuted(true);
  }, [salesforceChatDisabled, user, venue, salesforceENV, getAuthTokens]);
}

function useListenDomEventToOpenSalesforceChat(
  salesforceChatDisabled: boolean,
  getAuthTokens: () => Promise<SalesforceAuthTokens>
) {
  useEffect(() => {
    if (salesforceChatDisabled) return;

    window.document.addEventListener('click', (event) => {
      if ((<HTMLElement>event.target).closest(salesforceChatLuncherSelector)) openSalesforce({ getAuthTokens });
    });
  }, [salesforceChatDisabled, getAuthTokens]);
}

function useCustomSalesforceDOMEventsToIsLoading(setIsLoading: (state: boolean) => void) {
  useEffect(() => {
    window.document.addEventListener(SALESFORCE_CHAT_OPENED_REQUEST, () => setIsLoading(true));
    window.document.addEventListener(SALESFORCE_CHAT_OPENED, () => setIsLoading(false));
    window.document.addEventListener(SALESFORCE_CHAT_ERROR, () => setIsLoading(false));
  }, []);
}

type StartSalesforceChatInitParams = AuthTokensClientParams &
  UserAndVenue & {
    salesforceENV: NotOptionalRecord<SalesforceEnv>;
    onLoad: () => void;
  };
async function startSalesforceChat({
  user,
  venue,
  salesforceENV,
  onLoad,
  getAuthTokens,
}: StartSalesforceChatInitParams) {
  const authTokens: SalesforceAuthTokens = await getAuthTokens();
  const sfUserData: SalesforceChatPreChatFields = getPreChatFields({ user, venue, authTokens });
  logger.debug('%cuseSalesforceChat: loading script', 'font-size: 20px; color: skyblue;', sfUserData);

  addToSalesforceErrorContext('prechatFields', sfUserData);
  addToSalesforceErrorContext('UALA_SALESFORCE_BOOTSTRAP_CODE_URL', salesforceENV.UALA_SALESFORCE_BOOTSTRAP_CODE_URL);
  loadScript(salesforceENV.UALA_SALESFORCE_BOOTSTRAP_CODE_URL, () => {
    initSalesforceChat(sfUserData, authTokens.chatSessionToken);
    withCatchToError('SalesforceChat:loadScriptOnLoad', onLoad)();
  });
}

type GetPreChatFieldsParams = UserAndVenue & { authTokens: SalesforceAuthTokens };
function getPreChatFields({ user, venue, authTokens }: GetPreChatFieldsParams): SalesforceChatPreChatFields {
  if (!user.full_name) captureMessage(`[SalesforceChat:useSalesforceChat] userId ${user.id} has empty user.full_name`);
  if (!user.email) captureMessage(`[SalesforceChat:useSalesforceChat] userId ${user.id} has empty user.email`);

  return {
    externalUserFullName: String(user.full_name ?? ''),
    externalUserId: String(user.id),
    externalVenueId: String(venue.id),
    externalLanguage: String(venue.locale),
    externalSource: getExternalSource(),
    externalUserEmail: String(user.email ?? ''),
    externalCountry: String(venue.country?.code).toUpperCase(),
    profileAccessToken: authTokens.profileAccessToken,
  };
}
