import { captureException, withScope } from '@sentry/core';
import { Logger } from 'utils/logger';
import { catchedArgumentToErrorBuilder } from 'utils/sentryUtils';

const salesforceContext: Record<string, unknown> = {};

export const addToSalesforceErrorContext = (key: string, data: unknown) => {
  salesforceContext[key] = data;
};

const logger = new Logger('[SalesforceError]');
class SalesforceChatError extends Error {
  public context: Record<string, unknown>;

  public constructor(message: string) {
    super(message);
    this.context = salesforceContext;
  }
}
export const buildSalesforceChatError = (message: string) => new SalesforceChatError(message);
const catchedArgumentToSalesforceError = catchedArgumentToErrorBuilder(buildSalesforceChatError);

export const onSalesforceError = (error: SalesforceChatError) => {
  withScope((scope) => {
    scope.setExtras(salesforceContext);

    logger.error(error);
    captureException(error);
  });
};

export const withCatchToSalesforceError =
  <F extends (...args: any) => any>(namespace: string, fn: F) =>
  (...args: Parameters<F>) => {
    try {
      return fn(...args);
    } catch (e) {
      const error = catchedArgumentToSalesforceError(e, namespace);

      withScope((scope) => {
        scope.setExtras({ ...salesforceContext, arguments: args });

        logger.error(error);
        captureException(error);
      });
      return error;
    }
  };
